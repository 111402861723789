// getUploadJobFailedReasonI18nKey.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';

import { Status as UploadStatus } from '../resource/uploadConstants.js';

/**
 * Select upload job failed reason i18n key by upload job id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} uploadJobId - upload job id.
 * @return {string} The selected upload job failed reason i18n key.
 */
const getUploadJobFailedReasonI18nKey = createCachedSelector(
  (state, uploadJobId) =>
    getOperationData(state, ['uploadJob', uploadJobId], 'reason'),
  (state, uploadJobId) =>
    getOperationData(state, ['uploadJob', uploadJobId], 'status'),
  (reason, uploadJobStatus) => {
    if (UploadStatus.REVIEW_FAILED === uploadJobStatus) {
      if (reason) {
        return `review_reject_${reason.split('.')[0].toLowerCase()}`;
      }
      return 'review_reject_others';
    }
    if (reason) {
      return reason.toLowerCase();
    }
    return;
  }
)((state, uploadJobId) => `${uploadJobId}`);

export default getUploadJobFailedReasonI18nKey;
