import React from 'react';
import PropTypes from 'prop-types';

import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';

const { Provider, Consumer } = React.createContext({
  isFetching: false,
  hasMore: false,
  hasPrevious: false,
  isReverse: false,
  isBidirectional: false,
  root: undefined,
  rootMargin: '0px',
  nextPage: null,
  handleIsIntersecting: () => null,
});

const AXIS = ['x', 'y'];
const Loader = {
  MORE: 'loader-more',
  PREVIOUS: 'loader-previous',
};

class InfiniteScroller extends React.PureComponent {
  state = {
    isIntersecting: {
      [Loader.MORE]: false,
      [Loader.PREVIOUS]: false,
    },
  };
  componentDidMount() {
    this.attachScrollListener();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFetching, hasMore, hasPrevious } = this.props;
    const { isIntersecting } = this.state;
    const isFetchingChanged = isFetching !== prevProps.isFetching;
    if (
      isIntersecting[Loader.MORE] !== prevState.isIntersecting[Loader.MORE] ||
      hasMore !== prevProps.hasMore ||
      isFetchingChanged
    ) {
      this.loadMore();
    }
    if (
      isIntersecting[Loader.PREVIOUS] !==
        prevState.isIntersecting[Loader.PREVIOUS] ||
      hasPrevious !== prevProps.hasPrevious ||
      isFetchingChanged
    ) {
      this.loadPrevious();
    }
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  loadMore = () => {
    const { hasMore, isFetching, loadMore } = this.props;
    const { isIntersecting } = this.state;
    if (!hasMore || isFetching || !isIntersecting[Loader.MORE]) {
      return;
    }
    loadMore();
    return this.setState({
      isIntersecting: {
        ...this.state.isIntersecting,
        [Loader.MORE]: false,
      },
    });
  };

  loadPrevious = () => {
    const { hasPrevious, isFetching, loadPrevious } = this.props;
    const { isIntersecting } = this.state;
    if (!hasPrevious || isFetching || !isIntersecting[Loader.PREVIOUS]) {
      return;
    }
    loadPrevious();
    return this.setState({
      isIntersecting: {
        ...this.state.isIntersecting,
        [Loader.PREVIOUS]: false,
      },
    });
  };

  getTopPosition(domElement) {
    if (!domElement) {
      return 0;
    }

    const domElementOffset =
      this.props.axis === AXIS[0]
        ? domElement.offsetLeft
        : domElement.offsetTop;

    return domElementOffset + this.getTopPosition(domElement.offsetParent);
  }

  getOffset(domElement, scrollTop) {
    if (!domElement) {
      return 0;
    }

    const isAxisX = this.props.axis === AXIS[0];

    const domElementOffset = isAxisX
      ? domElement.offsetWidth
      : domElement.offsetHeight;

    const windowInnerSize = isAxisX ? window.innerWidth : window.innerHeight;

    return (
      this.getTopPosition(domElement) +
      (domElementOffset - scrollTop - windowInnerSize)
    );
  }

  setScrollComponent = ref => {
    this.scrollComponent = ref;
  };

  scrollListener = () => {
    const scrollComponent = this.scrollComponent;
    const scrollElement = window;
    const parentNode = scrollComponent?.parentNode;
    const { useWindow, axis, recordPosition } = this.props;
    const isAxisX = axis === AXIS[0];
    if (useWindow) {
      const doc =
        document.documentElement || document.body.parentNode || document.body;
      const scrollTop = isAxisX
        ? scrollElement.pageXOffset !== undefined
          ? scrollElement.pageXOffset
          : doc.scrollLeft
        : scrollElement.pageYOffset !== undefined
          ? scrollElement.pageYOffset
          : doc.scrollTop;

      recordPosition && recordPosition({ scrollTop });
    } else {
      if ((isAxisX && !parentNode?.clientWidth) || !parentNode?.clientHeight) {
        return;
      }
      recordPosition &&
        parentNode &&
        recordPosition({
          scrollTop: isAxisX ? parentNode.scrollLeft : parentNode.scrollTop,
        });
    }
  };

  getScrollElement = () => {
    const { useWindow } = this.props;
    return useWindow ? window : this.scrollComponent?.parentNode;
  };

  attachScrollListener() {
    const { recordPosition } = this.props;
    if (!recordPosition) {
      return;
    }
    const scrollElement = this.getScrollElement();
    scrollElement?.addEventListener('scroll', this.scrollListener, {
      passive: true,
    });
    scrollElement?.addEventListener('resize', this.scrollListener, false);
  }

  detachScrollListener() {
    const scrollElement = this.getScrollElement();
    scrollElement.removeEventListener('scroll', this.scrollListener, {
      passive: true,
    });
    scrollElement.removeEventListener('resize', this.scrollListener, false);
  }

  handleIsIntersecting = ({ type, isIntersecting }) => {
    this.setState({
      isIntersecting: {
        ...this.state.isIntersecting,
        [type]: isIntersecting,
      },
    });
  };

  render() {
    const {
      hasMore,
      hasPrevious,
      isFetching,
      isReverse,
      isBidirectional,
      useWindow,
      threshold,
      children,
      nextPage,
    } = this.props;
    const scrollComponent = React.Children.only(children);
    return (
      <Provider
        value={{
          hasMore,
          hasPrevious,
          isFetching,
          isReverse,
          isBidirectional,
          root: useWindow ? undefined : this.getScrollElement(),
          rootMargin: `${threshold}px`,
          handleIsIntersecting: this.handleIsIntersecting,
          nextPage,
        }}
      >
        {React.cloneElement(scrollComponent, {
          ref: this.setScrollComponent,
        })}
      </Provider>
    );
  }
}

InfiniteScroller.propTypes = {
  children: PropTypes.element,
  axis: PropTypes.oneOf(AXIS),
  isReverse: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  isBidirectional: PropTypes.bool,
  loadMore: PropTypes.func,
  loadPrevious: PropTypes.func,
  recordPosition: PropTypes.func,
  threshold: PropTypes.number,
  nextPage: PropTypes.number,
  useWindow: PropTypes.bool,
};

InfiniteScroller.defaultProps = {
  axis: AXIS[1],
  isFetching: false,
  hasMore: false,
  hasPrevious: false,
  isBidirectional: false,
  loadMore: () => null,
  loadPrevious: () => null,
  recordPosition: () => null,
  threshold: 250,
  nextPage: null,
  useWindow: true,
};

class ScrollItem extends React.PureComponent {
  renderItems = ({
    hasMore,
    hasPrevious,
    isFetching,
    isReverse,
    isBidirectional,
    root,
    rootMargin,
    nextPage,
    handleIsIntersecting,
  }) => {
    const { children, loader } = this.props;
    const getLoaderComponent = ({ type }) => {
      return (
        <WithIntersectionObserver
          key={`${type}-${nextPage}`}
          root={root}
          rootMargin={rootMargin}
          disabled={isFetching}
          onChange={({ isIntersecting }) => {
            handleIsIntersecting({
              isIntersecting,
              type: type,
            });
          }}
          shouldKeepObserve
        >
          {() => loader}
        </WithIntersectionObserver>
      );
    };
    if (isBidirectional) {
      let items = [
        hasPrevious ? getLoaderComponent({ type: Loader.PREVIOUS }) : null,
        ...children,
        hasMore ? getLoaderComponent({ type: Loader.MORE }) : null,
      ];
      if (isReverse) {
        items = [
          hasMore ? getLoaderComponent({ type: Loader.MORE }) : null,
          ...children,
          hasPrevious ? getLoaderComponent({ type: Loader.PREVIOUS }) : null,
        ];
      }
      return items.filter(item => item != null);
    }

    return hasMore
      ? isReverse
        ? [getLoaderComponent({ type: Loader.MORE }), ...children]
        : children.concat(getLoaderComponent({ type: Loader.MORE }))
      : children;
  };

  render() {
    return <Consumer>{this.renderItems}</Consumer>;
  }
}

ScrollItem.propTypes = {
  children: PropTypes.any,
  loader: PropTypes.element,
};

ScrollItem.defaultProps = {
  loader: <div>Loading...</div>,
};

export { InfiniteScroller, ScrollItem };
export default InfiniteScroller;
