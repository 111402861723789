// FeedMessages.jsx
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import ProfileCardItem from '../container/ProfileCardItem.js';
import OutboxMessage from '../container/OutboxMessage.js';
import MessageCard from '../container/MessageCard.js';
import FlixListItem from '../container/FlixListItem.js';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import HydrationBoundary from '../component/HydrationBoundary.jsx';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import Pagination from '../container/Pagination.js';
import Loading from '../component/Loading.jsx';
import {
  InfiniteScroller,
  ScrollItem,
} from '../component/InfiniteScroller.jsx';

import {
  CategoryLayout,
  CategoryComponentUi,
} from '../resource/feedConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';
import { ButtonId } from '../resource/mixpanel.js';

import media from '../style/media.js';
import TextEllipsis from '../style/TextEllipsis.js';

import MoreIconSource from '../../img/ic_arrow_right_white.svg';

const isClient = getIsInBrowserMainThread();

const MESSAGE_CARD_WIDTH = 128;
const MESSAGE_METADATA_CARD_WIDTH = 160;
const MESSAGE_CARD_HEIGHT = 172;
const MESSAGE_CARD_WIDTH_DESKTOP = 148;
const MESSAGE_METADATA_CARD_DESKTOP_WIDTH = 212;
const MESSAGE_CARD_HEIGHT_DESKTOP = 198;
const SHORTS_CARD_WIDTH = 160;
const SHORTS_METADATA_CARD_WIDTH = 112;
const SHORTS_CARD_HEIGHT = 240;
const SHORTS_CARD_WIDTH_DESKTOP = 212;
const SHORTS_METADATA_CARD_WIDTH_DESKTOP = 160;
const SHORTS_CARD_HEIGHT_DESKTOP = 320;

const paginationStyle = {
  marginTop: '20px',
};

export const FeedMessages = ({
  category = '',
  titleLink = '',
  moreLink = '',
  titleI18nId = '',
  layout = CategoryLayout.ROW,
  componentUi = CategoryComponentUi.MESSAGE_CARD,
  categoryIndex = null,
  nextPage = null,
  feedUnixTimestamp = null,
  maxPlaceholder = 10,
  totalCount = 0,
  currentPage = 1,
  itemCountPerRow = 10,
  itemCountPerPage = 20,
  shouldRefresh = false,
  shouldShowTitle = false,
  shouldShowTotalCount = false,
  shouldShowMoreLink = false,
  isAuthed = false,
  isCurrentPageFetched = false,
  isFirstPageFetched = false,
  isNextPageFetching = false,
  isShorts = false,
  isImage = false,
  isInPaginationMode = false,
  isTitleClickable = false,
  isProfileCategory = false, // TODO: change variable name
  messageIds = [],
  pinnedItemIds = [],
  listPath = [],
  locationState = {},
  fetchFeeds = () => null,
  ...restProps
}) => {
  const shouldShowHeader = shouldShowTitle || shouldShowMoreLink;
  const isProfileCardItemUi =
    CategoryComponentUi.PROFILE_CARD_ITEM === componentUi;
  const isMessageCardUi = CategoryComponentUi.MESSAGE_CARD === componentUi;
  const isFlixListItemUi = CategoryComponentUi.FLIX_LIST_ITEM === componentUi;
  const isRowLayout = CategoryLayout.ROW === layout;
  const { t } = useTranslation();
  const fetchFeedsByPage = useCallback(
    page => {
      if (isInPaginationMode && isCurrentPageFetched) return;
      fetchFeeds({
        isInPaginationMode: Boolean(isInPaginationMode),
        limit: itemCountPerPage,
        type: category,
        page,
        unixTimestamp: feedUnixTimestamp,
        shouldSkipLocalCache: shouldRefresh,
      });
    },
    [
      category,
      feedUnixTimestamp,
      fetchFeeds,
      itemCountPerPage,
      shouldRefresh,
      isInPaginationMode,
      isCurrentPageFetched,
    ]
  );
  useEffect(() => {
    fetchFeedsByPage(currentPage);
  }, [
    currentPage,
    category,
    feedUnixTimestamp,
    fetchFeeds,
    fetchFeedsByPage,
    isAuthed, // Needs to re-fetch feeds after user login, so put `isAuthed` in dependencies.
    itemCountPerPage,
    shouldRefresh,
    isInPaginationMode,
    isCurrentPageFetched,
  ]);
  useEffect(() => {
    return () => {
      if (!window.__IS_HYDRATED__) {
        window.__IS_HYDRATED__ = 'FeedMessages';
      }
    };
  }, []);
  const renderMessage = (messageId, index) => {
    const shouldHydrate = !isClient || !window.__IS_HYDRATED__;
    const reactKey = messageId || index;
    switch (layout) {
      case CategoryLayout.LIST: {
        if (isFlixListItemUi) {
          const Component = (
            <FlixListItem
              shouldUseLink
              shouldUseLazyImage={false}
              messageId={messageId}
              isPinned={pinnedItemIds.includes(messageId)}
              id={messageId}
              categoryId={category}
              itemIndexInCategory={index}
              {...restProps}
            />
          );
          return (
            <WithIntersectionObserver key={reactKey} threshold={0.2}>
              {({ isIntersecting }) => {
                return shouldHydrate ? (
                  <HydrationBoundary
                    shouldHydrate={isIntersecting}
                    wrapper={<ListFlixListItemWrapper />}
                  >
                    {Component}
                  </HydrationBoundary>
                ) : isIntersecting ? (
                  <ListFlixListItemWrapper>{Component}</ListFlixListItemWrapper>
                ) : (
                  <ListFlixListItemWrapper />
                );
              }}
            </WithIntersectionObserver>
          );
        }
        return (
          <ListOutboxMessageWrapper
            key={reactKey}
            as={Link}
            to={`/${isImage ? 'post' : 'story'}/${messageId}${isClient ? location.search : ''}`}
            data-element_id={ButtonId.Profile.ButtonPostClick}
            data-tracking_payload={{
              messageId,
              'discover.index': categoryIndex,
              'discover.category': category,
            }}
            state={locationState}
          >
            <OutboxMessage
              messageId={messageId}
              isPinned={pinnedItemIds.includes(messageId)}
              {...restProps}
            />
          </ListOutboxMessageWrapper>
        );
      }
      case CategoryLayout.GRID:
      case CategoryLayout.ROW:
      default: {
        const Component = isProfileCardItemUi ? (
          <ProfileCardItem
            id={messageId}
            itemIndexInCategory={index}
            messageId={messageId}
            categoryId={category}
            categoryIndex={categoryIndex}
            index={index}
            isPinned={pinnedItemIds.includes(messageId)}
            isShorts={isShorts}
            locationState={locationState}
            {...restProps}
          />
        ) : (
          <MessageCard
            id={messageId}
            categoryId={category}
            categoryIndex={categoryIndex}
            itemIndexInCategory={index}
            listPath={listPath}
            isShorts={isShorts}
            shouldWrapLink
            shouldTrackEvent
            shouldShowUser
            shouldShowTooltip
            shouldUseLazyThumbnail={false}
            {...restProps}
          />
        );
        if (CategoryLayout.GRID === layout) {
          return (
            <WithIntersectionObserver key={reactKey} threshold={0.2}>
              {({ isIntersecting }) => {
                return shouldHydrate ? (
                  <HydrationBoundary
                    shouldHydrate={isIntersecting}
                    wrapper={
                      <GridMessageWrapper isMessageCardUi={isMessageCardUi} />
                    }
                  >
                    {Component}
                  </HydrationBoundary>
                ) : isIntersecting ? (
                  <GridMessageWrapper isMessageCardUi={isMessageCardUi}>
                    {Component}
                  </GridMessageWrapper>
                ) : (
                  <GridMessageWrapper isMessageCardUi={isMessageCardUi} />
                );
              }}
            </WithIntersectionObserver>
          );
        }
        return (
          <WithIntersectionObserver key={reactKey} threshold={0.2}>
            {({ isIntersecting }) => {
              return shouldHydrate ? (
                <HydrationBoundary
                  shouldHydrate={isIntersecting}
                  wrapper={
                    <RowMessageWrapper
                      isShorts={isShorts}
                      isProfileCardItemUi={isProfileCardItemUi}
                    />
                  }
                >
                  {Component}
                </HydrationBoundary>
              ) : isIntersecting ? (
                <RowMessageWrapper
                  isShorts={isShorts}
                  isProfileCardItemUi={isProfileCardItemUi}
                >
                  {Component}
                </RowMessageWrapper>
              ) : (
                <RowMessageWrapper
                  isShorts={isShorts}
                  isProfileCardItemUi={isProfileCardItemUi}
                />
              );
            }}
          </WithIntersectionObserver>
        );
      }
    }
  };
  /**
   * Render `ProfileCardItem` as grid layout.
   */
  const renderGridProfileCardItems = () => {
    return (
      <GridProfileCardItems isShorts={isShorts}>
        <ScrollItem
          loader={
            <GridMessageWrapper isMessageCardUi={isMessageCardUi}>
              <Loading />
            </GridMessageWrapper>
          }
        >
          {renderMessages()}
        </ScrollItem>
      </GridProfileCardItems>
    );
  };
  /**
   * Render `MessageCard` as grid layout.
   */
  const renderGridMessageCards = () => {
    return (
      <GridMessageCards>
        {isInPaginationMode ? (
          renderMessages()
        ) : (
          <ScrollItem
            loader={
              <GridMessageWrapper isMessageCardUi={isMessageCardUi}>
                <Loading />
              </GridMessageWrapper>
            }
          >
            {renderMessages()}
          </ScrollItem>
        )}
      </GridMessageCards>
    );
  };
  /**
   * Render `FlixListItem` as list layout.
   */
  const renderListFlixListItems = () => {
    return (
      <ListFlixListItems>
        <ScrollItem
          loader={
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          }
        >
          {renderMessages()}
        </ScrollItem>
      </ListFlixListItems>
    );
  };
  /**
   * Render `OutboxMessage` as list layout.
   */
  const renderListOutboxMessageItems = () => {
    return (
      <ListOutboxMessageItems>
        <ScrollItem
          loader={
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          }
        >
          {renderMessages()}
        </ScrollItem>
      </ListOutboxMessageItems>
    );
  };
  /**
   * Render `ProfileCardItem` as row layout.
   */
  const renderRowProfileCardItems = () => {
    return (
      <RowProfileCardItemsWrapper>
        <RowProfileCardItems>
          <ScrollItem
            loader={
              <RowMessageWrapper
                isShorts={isShorts}
                isProfileCardItemUi={isProfileCardItemUi}
              >
                <Loading />
              </RowMessageWrapper>
            }
          >
            {renderMessages()}
          </ScrollItem>
        </RowProfileCardItems>
      </RowProfileCardItemsWrapper>
    );
  };
  /**
   * Render `MessageCard` as row layout.
   */
  const renderRowMessageCards = () => {
    return (
      <RowMessageCardsWrapper>
        <RowMessageCards>
          {isInPaginationMode ? (
            renderMessages()
          ) : (
            <ScrollItem
              loader={
                <RowMessageWrapper
                  isShorts={isShorts}
                  isProfileCardItemUi={isProfileCardItemUi}
                >
                  <Loading />
                </RowMessageWrapper>
              }
            >
              {renderMessages()}
            </ScrollItem>
          )}
        </RowMessageCards>
      </RowMessageCardsWrapper>
    );
  };
  const renderItemsByLayout = () => {
    return CategoryLayout.GRID === layout
      ? isProfileCardItemUi
        ? renderGridProfileCardItems()
        : renderGridMessageCards()
      : CategoryLayout.LIST === layout
        ? isFlixListItemUi
          ? renderListFlixListItems()
          : renderListOutboxMessageItems()
        : isProfileCardItemUi
          ? renderRowProfileCardItems()
          : renderRowMessageCards();
  };
  const renderMessages = () =>
    messageIds.length
      ? isInPaginationMode && isRowLayout
        ? messageIds.slice(0, itemCountPerRow).map(renderMessage)
        : messageIds.map(renderMessage)
      : Array(maxPlaceholder).fill(null).map(renderMessage);
  if (
    (isInPaginationMode ? isCurrentPageFetched : isFirstPageFetched) &&
    !messageIds.length
  )
    return null;
  return (
    <StyledFeedStoryGrid>
      {shouldShowHeader && (
        <Header>
          {shouldShowTitle && (
            <Title
              to={titleLink}
              as={isTitleClickable ? Link : undefined}
              replace={isProfileCategory}
            >
              {t(titleI18nId, {
                ns: TranslationNamespace.FEED,
              })}
              {shouldShowTotalCount && (
                <TotalCount>({totalCount || 0})</TotalCount>
              )}
            </Title>
          )}
          {shouldShowMoreLink && (
            <MoreLink
              to={moreLink}
              data-element_id={ButtonId.All.ButtonMore}
              data-tracking_payload={{
                'discover.index': categoryIndex,
                'discover.category': category,
              }}
            >
              {t('more', {
                ns: TranslationNamespace.GENERAL,
              })}
              <img src={MoreIconSource} aria-hidden />
            </MoreLink>
          )}
        </Header>
      )}
      <Body $isRowLayout={isRowLayout}>
        {/* TODO: The goal is to completely remove InfiniteScroller after the comprehensive migration. */}
        {isInPaginationMode ? (
          renderItemsByLayout()
        ) : (
          <InfiniteScroller
            {...(CategoryLayout.ROW === layout
              ? {
                  axis: 'x',
                  useWindow: false,
                }
              : {
                  useWindow: true,
                })}
            pageStart={1}
            threshold={100}
            hasMore={nextPage != null}
            isFetching={isNextPageFetching || !isFirstPageFetched}
            loadMore={() => {
              fetchFeeds({
                type: category,
                page: nextPage,
                limit: itemCountPerPage,
                unixTimestamp: feedUnixTimestamp,
              });
            }}
          >
            {renderItemsByLayout()}
          </InfiniteScroller>
        )}
        {isInPaginationMode && !isRowLayout && (
          <Pagination
            totalPages={Math.ceil(totalCount / itemCountPerPage)}
            currentPage={currentPage}
            style={paginationStyle}
          />
        )}
      </Body>
    </StyledFeedStoryGrid>
  );
};

FeedMessages.propTypes = {
  category: PropTypes.string,
  titleLink: PropTypes.string,
  moreLink: PropTypes.string,
  titleI18nId: PropTypes.string,
  layout: PropTypes.string,
  componentUi: PropTypes.string,
  categoryIndex: PropTypes.number,
  nextPage: PropTypes.number,
  feedUnixTimestamp: PropTypes.number,
  maxPlaceholder: PropTypes.number,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  itemCountPerRow: PropTypes.number,
  itemCountPerPage: PropTypes.number,
  isAuthed: PropTypes.bool,
  isCurrentPageFetched: PropTypes.bool,
  isFirstPageFetched: PropTypes.bool,
  isNextPageFetching: PropTypes.bool,
  isProfileCategory: PropTypes.bool,
  isShorts: PropTypes.bool,
  isImage: PropTypes.bool,
  isInPaginationMode: PropTypes.bool,
  isTitleClickable: PropTypes.bool,
  shouldRefresh: PropTypes.bool,
  shouldShowTitle: PropTypes.bool,
  shouldShowTotalCount: PropTypes.bool,
  shouldShowMoreLink: PropTypes.bool,
  messageIds: PropTypes.array,
  pinnedItemIds: PropTypes.array,
  listPath: PropTypes.array,
  locationState: PropTypes.object,
  fetchFeeds: PropTypes.func,
};

const HorizontalPadding = css`
  padding-left: 16px;
  padding-right: 16px;
  ${media.mobile`
  `}
`;

const HideScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledFeedStoryGrid = styled.div``;

const Header = styled.header`
  ${HorizontalPadding};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  ${TextEllipsis};
  margin-right: 10px;
  flex: auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  ${media.mobile`
    font-size: 18px;
  `}
`;

const TotalCount = styled.span`
  padding-left: 8px;
  color: var(--font-color-primary-on-dark, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.014px;
  ${media.mobile`
    font-size: 12px;
    letter-spacing: 0.012px;
  `}
`;

const MoreLink = styled(Link)`
  flex: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  ${media.mobile`
    font-size: 12px;
  `}
  >img {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;

const Body = styled.div`
  ${({ $isRowLayout }) => css`
    min-height: ${$isRowLayout ? 'unset' : '75vh'};
    padding-bottom: ${$isRowLayout ? 'unset' : '40px'};
  `};
`;

const GridProfileCardItems = styled.div`
  ${HorizontalPadding};
  ${({ isShorts }) => css`
    padding-top: 16px;
    padding-bottom: 16px;
    display: grid;
    gap: 20px;
    justify-content: center;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${isShorts ? 168 : 200}px, 1fr)
    );
    place-items: center;
    ${media.mobile`
      gap: 8px;
      grid-template-columns: repeat(
        auto-fill,
        minmax(${isShorts ? 112 : 140}px, 1fr)
      );
    `};
  `}
`;

const GridMessageCards = styled.div`
  ${HorizontalPadding};
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
  ${media.mobile`
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  `};
`;

const ListFlixListItems = styled.div`
  ${HideScrollbar};
`;

const ListOutboxMessageItems = styled.div`
  ${HideScrollbar};
`;

const RowProfileCardItemsWrapper = styled.div`
  ${HorizontalPadding};
  ${HideScrollbar};
  overflow-x: auto;
  overflow-y: hidden;
`;

const RowProfileCardItems = styled.div`
  display: inline-flex;
`;

const RowMessageCardsWrapper = styled.div`
  ${HorizontalPadding};
  ${HideScrollbar};
  overflow-x: auto;
  overflow-y: hidden;
`;

const RowMessageCards = styled.div`
  display: inline-flex;
`;

const GridMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ isMessageCardUi }) => {
    if (isMessageCardUi) {
      return css`
        aspect-ratio: ${MESSAGE_CARD_WIDTH / MESSAGE_CARD_HEIGHT};
        @supports not (aspect-ratio: auto) {
          position: relative;
          padding-top: ${`${parseInt(
            (MESSAGE_CARD_HEIGHT / MESSAGE_CARD_WIDTH) * 100
          )}%`};
          > div {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
        }
      `;
    }
  }}
`;

const ListFlixListItemWrapper = styled.div`
  ${HorizontalPadding};
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.15);
  padding-bottom: 8px;
  :first-child {
    margin-top: 12px;
  }
  :last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }
`;

const ListOutboxMessageWrapper = styled.div`
  ${HorizontalPadding};
  border-bottom: 1px solid rgba(100, 100, 100, 0.15);
  display: block;
  :last-child {
    border-bottom: none;
  }
  ${media.mobile`
  `};
`;

const RowMessageWrapper = styled.div`
  width: ${({ isShorts, isProfileCardItemUi }) =>
    isShorts
      ? isProfileCardItemUi
        ? SHORTS_METADATA_CARD_WIDTH_DESKTOP
        : SHORTS_CARD_WIDTH_DESKTOP
      : isProfileCardItemUi
        ? MESSAGE_METADATA_CARD_DESKTOP_WIDTH
        : MESSAGE_CARD_WIDTH_DESKTOP}px;
  height: ${({ isShorts, isProfileCardItemUi }) =>
    isProfileCardItemUi
      ? 'auto'
      : isShorts
        ? `${SHORTS_CARD_HEIGHT_DESKTOP}px`
        : `${MESSAGE_CARD_HEIGHT_DESKTOP}px`};
  margin-right: 8px;
  :last-child {
    margin-right: 0;
  }
  ${media.mobile`
    width: ${({ isShorts, isProfileCardItemUi }) =>
      isShorts
        ? isProfileCardItemUi
          ? SHORTS_METADATA_CARD_WIDTH
          : SHORTS_CARD_WIDTH
        : isProfileCardItemUi
          ? MESSAGE_METADATA_CARD_WIDTH
          : MESSAGE_CARD_WIDTH}px;
    height: ${({ isShorts, isProfileCardItemUi }) =>
      isProfileCardItemUi
        ? 'auto'
        : isShorts
          ? `${SHORTS_CARD_HEIGHT}px`
          : `${MESSAGE_CARD_HEIGHT}px`};
  `};
`;

const LoadingWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default FeedMessages;
