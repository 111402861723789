// getMessageCardLink.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';

const isServer = typeof window === 'undefined';

/**
 * Select message card link by id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - the id of message.
 * @param {string[]} listPath - the path of the messages list in redux store.
 * @param {boolean} isShorts - is shorts message.
 * @return {Object} The selected message card link.
 */
const getMessageCardLink = createCachedSelector(
  () => null,
  (state, messageId) => messageId,
  (state, messageId, listPath) => listPath,
  (state, messageId, listPath, isShorts) => isShorts,
  (state, messageId, listPath, isShorts) => {
    if (!messageId) {
      return null;
    }
    let result = {};

    if (messageId) {
      if (isShorts) {
        result.pathname = `/shorts/${messageId}`;
      } else if (isServer) {
        result.pathname = `/story/${messageId}`;
      } else {
        // Might loss location.state if pathname changed, so check current pathname first to prevent this issue.
        const isOnPostDetail = getIsMatchedPathname({
          pathname: location?.pathname,
          dataKey: 'isOnPostDetail',
        });
        result.pathname = isOnPostDetail
          ? `/post/${messageId}`
          : `/story/${messageId}`;
      }
    }

    if (listPath?.[0] === 'search') {
      result.pathname = `/message/${messageId}`;
    }

    if (listPath) {
      if (result.state == null) {
        result.state = {};
      }

      result.state.listPath = listPath;

      if (isShorts) {
        result.state.category = listPath[2];
      }
    }
    return result;
  }
)(
  (state, messageId, listPath, isShorts) =>
    `${messageId}:${listPath}:${isShorts}`
);

export default getMessageCardLink;
