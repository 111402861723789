// getUploadJobStatusI18nKey.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';

import { Status as UploadStatus } from '../resource/uploadConstants.js';

/**
 * Select upload job status i18n key by upload job id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} uploadJobId - upload job id.
 * @return {status} The selected upload job status i18n key.
 */
const getUploadJobStatusI18nKey = createCachedSelector(
  (state, uploadJobId) =>
    getOperationData(state, ['uploadJob', uploadJobId], 'status'),
  uploadJobStatus => {
    switch (uploadJobStatus) {
      case UploadStatus.PENDING:
        return 'general_pending';
      case UploadStatus.DELETING:
        return 'outbox_delete_message';
      case UploadStatus.UPLOAD_STARTED:
        return 'notification_status_uploading';
      case UploadStatus.FAILED:
      case UploadStatus.UPLOAD_FAILED:
        return 'uploading_failed';
      case UploadStatus.PROCESSING_FAILED:
        return 'alert_processing_failed';
      case UploadStatus.DELIVERY_FAILED:
        return 'alert_delivery_failed';
      case UploadStatus.UPLOAD_COMPLETED:
        return 'upload_successed';
      case UploadStatus.PROCESSING_STARTED:
      case UploadStatus.PROCESSING_COMPLETED:
        return 'outbox_transcoding';
      case UploadStatus.REVIEW_STARTED:
        return 'status_review_started';
      case UploadStatus.REVIEW_FAILED:
        return 'status_review_failed';
      case UploadStatus.REVIEW_COMPLETED:
      case UploadStatus.DELIVERY_STARTED:
        return 'status_review_completed';
      case UploadStatus.DELIVERY_COMPLETED:
        return 'status_published';
      default:
        return 'general_pending';
    }
  }
)((state, uploadJobId) => `${uploadJobId}`);

export default getUploadJobStatusI18nKey;
